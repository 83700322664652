import React, { useRef, useState } from 'react';
import './App.css';
import pdfMake from 'pdfmake';
import SignatureCanvas from 'react-signature-canvas';
import vfsFonts from "pdfmake/build/vfs_fonts";
import dummyTemplate from './pdf_templates/dummy';
import QRCode from 'qrcode.react';
import Label from 'react-bootstrap/FormLabel';


function App() {
  const [note, setNote] = useState("");
  const [showQRCode, setShowQRCode] = useState(false);
  const signature = useRef();

  function submit() {
    if (!signature.current.isEmpty()) {
      let image = signature.current.getTrimmedCanvas().toDataURL('image/png');
      const { vfs } = vfsFonts.pdfMake;
      pdfMake.vfs = vfs;
      let docDefinition = dummyTemplate(image, note);
      pdfMake.createPdf(docDefinition).download();
    }
  }

  function openPdf() {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let docDefinition = dummyTemplate(null, "");
    pdfMake.createPdf(docDefinition).download();
  }

  function downloadQRCode() {
    const canvas = document.getElementById("1");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <div className="container">

      <div className="row p-0">
        <div className="col-md-8 offset-md-2 col-sm-12">
          <div>
            <h1>
              Please sign here
    </h1>
            <div style={{ border: "1px solid lightgrey", width: "100%" }}>
              <SignatureCanvas
                ref={signature}
                penColor='green'
                canvasProps={{ height: 300, className: 'sigCanvas w-100' }} />
            </div>
            <Label>Notes:(Optional)</Label>
            <div>
              <input
                className="form-control"
                type="text"
                onChange={(e) => setNote(e.target.value)}>
              </input>
            </div>
            <button
              className="btn btn-primary btn-sm"
              onClick={submit}>
              Signed Document
          </button>
            <button
              className="btn btn-secondary btn-sm ml-1"
              onClick={openPdf}>
              Document
          </button>
            <button
              className="btn btn-danger btn-sm m-1"
              onClick={() => signature.current.clear()}>
              Clear
          </button>
            {
              showQRCode ?
                <>
                  <button
                    className="btn btn-success btn-sm ml-1"
                    onClick={downloadQRCode}>
                    Download QR Code
                </button>
                  <div>
                    <QRCode
                      id="1"
                      value="https://microserverless.web.app/"
                      size={290}
                      level={"H"}
                      includeMargin={true}
                    />
                  </div>
                </>
                :
                <button
                  className="btn btn-success btn-sm"
                  onClick={setShowQRCode}>
                  Generate QR Code
          </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
