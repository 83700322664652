function getHeader() {
    return [
        [{
            colSpan: 4,
            text: "Flexo Line Products (Pty)Ltd",
            alignment: 'center',
            fontSize: 16,
            bold: true

        },
        {},
        {},
        {}
        ],

        [
            {
                colSpan: 4,
                text: 'PO Box 422, Paarden Eiland, 7420',
                alignment: 'center',
                fontSize: 12,
                bold: true

            },
            {},
            {},
            {}
        ],
        [
            {},
            {
                text: 'Flexo Atlas Gardens',
                fontSize: 10,
                bold: true

            },
            {
                colSpan: 2,
                text: 'Atlantis',
                alignment: 'left',
                fontSize: 10,
                bold: true

            },
            {}
        ],
        [{
            text: 'Physical:',
            fontSize: 8
        },
        {
            text: 'Unit 1, Atlas Gardens, Contermanskloof',
            fontSize: 8
        },
        {
            colSpan: 2,
            text: 'Cnr. Neil Hare / Jan Van Niekerk Street Atlantis, 7349',
            fontSize: 8,


        },
        {}
        ],
        [{
            text: 'Phone:',
            fontSize: 8
        },
        {
            text: '(021) 511 7578',
            fontSize: 8
        },
        {
            colSpan: 2,
            text: '(021) 577 1100',

            fontSize: 8,


        },
        {}
        ],
        [{
            text: 'Fax:',
            fontSize: 8
        },
        {
            text: '(021) 511 9556',
            fontSize: 8
        },
        {
            colSpan: 2,
            text: '(021) 577 1794',

            fontSize: 8,


        },
        {}
        ],
        [{
            text: 'Email:',
            fontSize: 8
        },
        {
            text: 'info@flexonline.co.za',
            fontSize: 8
        },
        {



        },
        {}
        ],
        [{
            text: 'Web:',
            fontSize: 8
        },
        {
            text: 'www.flexonline.co.za',
            fontSize: 8
        },
        {



        },
        {}
        ],
    ];
};


function getCustomerAddress() {

    let result = [
        [{ text: "Google", alignment: "left", bold: true, fontSize: 13 }],

    ];

    result.push(
        [{ text: "South Africa" }],
    )



    result.push(
        [{ text: "Cape Town" }],
    )



    result.push(
        [{ text: "8888" }],
    )


    return result
}


function printDeliveryLines() {
    let result = [];
    result.push([
        { text: "Order Number:", style: "productTableHeader" },
        { text: "Product Description:", style: "productTableHeader" },
        { text: "Product Code", style: "productTableHeader" },
        { text: "BINS", style: "productTableHeader" },
        { text: "PACKS/BIN", style: "productTableHeader" },
        { text: "PER PACK", style: "productTableHeader" },
        { text: "QTY SUPPLIED", style: "productTableHeader" }
    ]);

    result.push([
        { text: "11223344" },
        { text: "test" },
        { text: "test_code" },
        { text: "1", alignment: 'right' },
        { text: "2", alignment: 'right' },
        { text: "3", alignment: 'right' },
        { text: "1", alignment: 'right' }

    ]);


    return result;

}

export default (signatureImageData, note) => {


    var dd = {
        pageOrientation: 'landscape',
        pageMargins: [40, 220, 40, 100],

        header: function () {

            return [
                {
                    columns: [
                        {
                            margin: [20, 20, 40, 10],
                            alignment: 'justify',
                            columns: [
                                // getCompanyLogo("Flexo")

                                {
                                    table: {
                                        widths: [40, '*', '*', 30],
                                        body: getHeader()

                                    },
                                    layout: 'noBorders',


                                }

                            ]
                        }
                    ]
                },
                { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 800, y2: 10, lineWidth: 2, alignment: 'center' }], alignment: 'center' },

                { text: `DELIVERIES`, bold: true, alignment: 'center', fontSize: 18, marginTop: 10 },
            ]
        },

        content: [

            //Print Document Title


            //Print Customer Address and Deliver Details
            {
                columns: [
                    {
                        margin: [0, 20, 0, 0],
                        style: { bold: true, fontSize: 9 },

                        table: {
                            widths: ['*'],

                            body: getCustomerAddress()
                        },

                    },
                    {},
                    {
                        margin: [0, 20, 0, 0],
                        style: { bold: true, fontSize: 9 },

                        table: {
                            widths: ['*', '*'],
                            body: [

                                [{ text: "Nanosoft", alignment: "left", colSpan: 2, bold: true, fontSize: 13 }, {}],
                                [{ text: "Delivery Date", alignment: "left" }, "11-Sep-2020"],
                                [{ text: "Delivery Note", alignment: "left" }, note],
                                [{ text: "Invoice # office use", alignment: "left" }, ""],


                            ]
                        },

                        alignment: 'left'
                    },

                ]
            },
            {
                style: { bold: true, fontSize: 7 },
                margin: [0, 20, 0, 0],
                table: {
                    headerRows: 1,
                    widths: [100, '*', 100, 70, 70, 70, 70],
                    body: printDeliveryLines()
                },

            },

        ],

        footer: function (currentPage = 1, pageCount = 1) {

            return {
                margin: [40, 10, 40, 20],
                columns: [
                    signatureImageData ?
                        {
                            columns: [
                                {
                                    text: `Signature:`,
                                    bold: true,
                                    alignment: 'left',
                                    marginTop: 20,
                                    fontSize: 14,
                                    width: 70
                                },
                                {
                                    image: signatureImageData,
                                    marginTop: 20,
                                    width: 50,
                                    height: 25,
                                    alignment: 'left'
                                }
                            ]
                        }
                        :
                        {
                            text: `Signature: ______________`,
                            bold: true,
                            alignment: 'left',
                            marginTop: 20,
                            fontSize: 14
                        },

                    {
                        alignment: 'center',
                        marginTop: 20,
                        style: { fontSize: 7 },
                        table: {
                            widths: ['*'],
                            body: [

                                [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount }],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        text: `Date: 06-Oct-2020`,
                        bold: true,
                        alignment: 'right',
                        marginTop: 20,
                        fontSize: 14
                    }
                ],
            }

        },
        styles: {
            header: {
                fontSize: 18,
                bold: true
            },
            bigger: {
                fontSize: 15,
                italics: true
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            },
            productTableHeader: {
                bold: true,
                fontSize: 9,
            }
        },
        defaultStyle: {
            columnGap: 20
        }


    }

    // documents.forEach((document, i) => {
    //     dd.content = dd.content.concat(content(document, i < documents.length - 1))
    // })
    return dd;
}

